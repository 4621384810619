import { Controller } from "@hotwired/stimulus"

class Clipboard extends Controller {
  static targets = ["source"]

  _getText(sourceMethod, target) {
    if (sourceMethod === "value") {
      return this.sourceTarget.value
    } else if (sourceMethod === "dataset") {
      return target.dataset.clipboardDataText
    } else {
      console.error("Could not get text to copy to clipboard")
      return ""
    }
  }

  async copy(event) {
    const params = {
      ...event.params,
      ...(event.detail || {}),
    }
    const { sourceMethod, confirmation, currentTarget = event.currentTarget } = params

    const text = this._getText(sourceMethod, currentTarget)
    if (text === "") {
      alert("Impossible de copier le texte dans le presse-papier")
      return
    }
    try {
      await navigator.clipboard.write([new ClipboardItem({
        "text/plain": new Blob([text], { type: "text/plain" }),
        "text/html": new Blob([text], { type: "text/html" }),
      })])
      if (confirmation === "visual") {
        this.dispatch("copy", {
          detail: {
            currentTarget,
          },
        })
      } else {
        alert("Le contenu a bien été copié dans le presse-papier.")
      }
    } catch (err) {
      window.prompt("Copier dans le presse-papier : Cmd + C, Entrée", text)
    }
  }
}

export { Clipboard }
