import { Controller } from "@hotwired/stimulus"

class RichComponent extends Controller {
  static values = {
    jsBundleUrl: String,
    cssBundleUrl: String,
    type: String,
  }

  async connect() {
    await this.loadBundles(this.jsBundleUrlValue, this.cssBundleUrlValue)
    this.runComponent(this.typeValue, this.element)
    this.showComponent(this.element)
  }

  loadBundles(jsBundleUrl, cssBundleUrl) {
    if (window.richComponentFrameworkPromise) {
      return window.richComponentFrameworkPromise
    }

    window.richComponentFrameworkPromise = new Promise((resolve, reject) => {
      const script = document.createElement("script")
      script.src = jsBundleUrl
      script.onload = () => resolve()
      script.onerror = () => reject(new Error(`Failed to load script ${jsBundleUrl}`))
      document.head.appendChild(script)
    })

    const link = document.createElement("link")
    link.rel = "stylesheet"
    link.href = cssBundleUrl
    document.head.appendChild(link)

    return window.richComponentFrameworkPromise
  }

  runComponent(type, node) {
    if (!type || !node) {
      console.warn("Missing type or node")
      return
    }
    const { richComponentRegistry } = window
    if (!richComponentRegistry) {
      console.warn("Rich component registry is not loaded")
      return
    }
    const component = richComponentRegistry[type]
    if (!component) {
      console.warn(`No rich component found for type ${type}`)
      return
    }
    component(node)
  }

  showComponent(node) {
    node.classList.remove("-hidden")
  }
}

export { RichComponent }
