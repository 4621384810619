import { Controller } from "@hotwired/stimulus"

class Captcha extends Controller {
  static targets = ["captcha", "validation"]

  captchaTargetConnected(element) {
    element.addEventListener("verified", () => {
      this.validationTarget.value = "true"
      this.validationTarget.dispatchEvent(new Event("input"))
    })
  }
}

export { Captcha }
